// JS ----------
import $ from 'jquery';
global.$ = jQuery;

import "src/js/slick.min";
import "src/js/common";

import Rails from 'rails-ujs'
Rails.start()

// CSS ----------
const images = require.context('../../images/', true)
import 'src/scss/common/visitor';
