// JavaScript Document

// スムーズスクロール部分の記述
$(function(){
  // #で始まるアンカーをクリックした場合に処理
  $('a[href^="#"]').click(function() {
    // スクロールの速度
    var speed = 800; // ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を数値で取得
    var position = target.offset().top;
    // スムーススクロール
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
});

//gloval navigation
$(function(){
  $('#gNav .btn a').click(function(e){
    $('#overlay').fadeToggle();
    $('#gNav .nav').slideToggle(300);
    $("#gNav .btn").toggleClass('active');
    $("#gNav .menu-trigger").toggleClass('active');
    $("#header").toggleClass('active');
    e.preventDefault();
  });
  $("#overlay , .menu-trigger.active , #gNav .nav li a").click(function(e){
      $('#overlay').fadeToggle();
      $("#gNav .nav").slideUp();
      $("#gNav .btn").removeClass('active');
      $("#gNav .menu-trigger").removeClass('active');
      $("#header").removeClass('active');
  });
});

//gloval navigation 内のmore
$(function(){
  $('#gNav .more a').on('click', function() {
    $("#gNav .more").fadeToggle('fast');//押したボタンを非表示
    $("#gNav .close").fadeToggle(); //おりたたむを表示
    $('.nav-category-list-more').slideToggle();
  });
  $('#gNav .close a').on('click', function() {
    $("#gNav .close").fadeToggle();//押したボタンを非表示
    $("#gNav .more").fadeToggle(); //もっと見るを表示
    $('.nav-category-list-more').slideToggle();
  });
});